<template>
  <PageWithHeader>
    <template #title>
      <t path="home_39">Leaderboard</t>
    </template>
    <template #body>
      <Scroller>
        <div class="px-16 fill df-col flex-1">
          <div class="f-md my-16" style="line-height: 20px">
            <t as="div" path="leaderboard_2" multiline #="{td}" custom>
              <p class="mb-4" v-for="(p, index) in td" :key="index">{{p}}</p>
            </t>
          </div>
          <TheDateSelect @change="dateChange"/>
          <div class="df-col flex-1 mb-32">
            <div class="i-table df-col my-16 flex-1 pb-32">
              <Holder :content="listData" :skeleton-repeat="1" holder-class="px-16">
                <List :list-data="listData" #="{ item }" pull-refresh @refresh="refresh">
                  <RankItem :item="item" />
                </List>
              </Holder>
            </div>
          </div>
        </div>
      </Scroller>
    </template>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheDateSelect from '@/pages/profitRank/components/TheDateSelect.vue'
import RankItem from '@/pages/profitRank/components/RankItem.vue'
import {
  readRankList,
  TRankItem,
} from '@/pages/profitRank/profitRank.api'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'ProfitRank',
  components: {
    PageWithHeader,
    RankItem,
    TheDateSelect,
    List,
    Holder,
    Scroller,
  },
  setup () {
    const dateVal = shallowRef('')
    const listData = shallowRef<TRankItem[]>([])
    const dateChange = (date: string) => {
      dateVal.value = date
      refresh()
    }

    const refresh = () => {
      readRankList({ issue: dateVal.value }).then(res => {
        if (!res) {
          listData.value = []
          return
        }
        listData.value = res.map(item => {
          item.closeTime = Number.isNaN(Number(item.closeTime)) ? '--' : formatDateTime('MM-dd-yyyy HH:mm:ss', Number(item.closeTime))
          item.createTime = Number.isNaN(Number(item.createTime)) ? '--' : formatDateTime('MM-dd-yyyy HH:mm:ss', Number(item.createTime))
          item.closePrice = String(Number(item.closePrice))
          item.createPrice = String(Number(item.createPrice))
          return item
        })
      })
    }

    return {
      dateVal,
      listData,
      dateChange,
      refresh,
    }
  },
})
</script>
<style scoped lang="scss">
.t-item {
  font-size: 15px;
  font-weight: 400;
}
.td-level {
  span:nth-child(1) {
    flex: 1;
  }
  span:nth-child(2) {
    flex: 1;
    text-align: center;
  }
  span:nth-child(3) {
    flex: 1;
    text-align: right;
  }
}
</style>
