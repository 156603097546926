<template>
  <div class="r-item">
    <div class="base-data df-middle">
      <span style="min-width: 18px;max-width: 18px;">{{item.rankNum}}</span>
      <div class="icon-wrap ml-16">
        <Pic :src="item.avatarPic" fallback="/img/user/Avatar@2x.png" width="32" height="32" style="object-fit: contain; border-radius: 50%;"/>
        <Pic v-if="item.rankNum === 1" src="rank/crown" width="16" height="16" class="crown" />
      </div>
      <div class="l-right flex-1 ml-16 df-middle df-between" :style="showMore?'':'border-bottom: 1px solid var(--color-border)'">
        <span class="nickname f-bold f-md">{{item.nickName}}</span>
        <ColorText class="flex-1 pl-12 f-bold" :value="item.profitability" #={value}>
          {{item.profitability}}%
        </ColorText>
        <div class="df-middle price">
          <Pic src="rank/coupon" width="16" height="16" class="crown" />
          <money :v="item.voucherAmount" class="ml-4 price-text"/>
          <div class="toggle-wrap df-middle" style="justify-content: flex-end;" @click="HandleTaggle">
            <Pic :src="showMore?'rank/fold_up':'rank/expand'" width="16" height="16" class="crown" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMore" class="more-data br-lg d-f f-md px-16 pt-16 pb-8">
      <div class="left flex-2">
        <p class="mb-8">
          <t path="leaderboard_3" class="mr-4 c-title">Symbol</t>
          <span class="value">{{prop('contactCode', item)}}</span>
        </p>
        <p class="mb-8">
          <t path="leaderboard_5" class="mr-4 c-title">Margin</t>
          <money :v="prop('amount', item)"/>
        </p>
        <p class="mb-8">
          <t path="leaderboard_7" class="mr-4 c-title">Entry Price</t>
          <span class="value">{{prop('createPrice', item)}}</span>
        </p>
        <p class="mb-8">
          <t path="leaderboard_9" class="mr-4 c-title">Close Price</t>
          <span class="value">{{prop('closePrice', item)}}</span>
        </p>
      </div>
      <div class="right flex-3 pl-8">
        <p class="mb-8">
          <t path="leaderboard_4" class="mr-4 c-title">Direction</t>
          <t custom #="{td, t}">
            <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
              :value="prop('type', item) - 2" />
          </t>
        </p>
        <p class="mb-8">
          <t path="leaderboard_6" class="mr-4 c-title">Profit%</t>
          <ColorText class="value" :value="item.profitability" #={value}>
            {{item.profitability}}%
          </ColorText>
        </p>
        <p class="mb-8">
          <t path="leaderboard_8" class="mr-4 c-title">Entry Time</t>
          <span class="value f-xs">{{prop('createTime', item)}}</span>
        </p>
        <p class="mb-8">
          <t path="leaderboard_10" class="mr-4 c-title">Close Time</t>
          <span class="value f-xs">{{prop('closeTime', item)}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'RankItem',
  components: {
    Pic,
    ColorText,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const showMore = shallowRef(false)
    const HandleTaggle = () => {
      showMore.value = !showMore.value
    }

    return {
      showMore,
      HandleTaggle,
    }
  },
})

</script>

<style scoped lang="scss">
.r-item{
  .base-data{
    height: 56px;
    .icon-wrap{
      position: relative;
      .crown{
        position: absolute;
        top: -8px;
        right: 0;
      }
    }
    .l-right{
      height: 56px;
      .nickname{
        min-width: 80px;
        max-width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .price{
        max-width: 110px;
        .price-text{
          max-width: 62px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          overflow: hidden;
        }
      }
    }
  }
  .more-data{
    background-color: var(--color-background);
  }

  .toggle-wrap{
    height: 50px;
    min-width: 28px;
    max-width: 28px;
  }
}
</style>
