<template>
  <div class="date-sel df-middle df-between">
    <div class="change-btn pre" @click="change(-1)">
      <span :style="!preAble?'border-right-color: var(--color-grey)':''"></span>
    </div>
    <div class="date">{{ curDate }}</div>
    <div class="change-btn next" @click="change(1)">
      <span :style="!nextAble?'border-left-color: var(--color-grey)':''"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'TheDateSelect',
  emits: ['change'],
  setup (props, ctx) {
    const preAble = shallowRef(false)
    const nextAble = shallowRef(false)
    const dateList = [] as string[]
    const curDate = shallowRef('')
    const dayNumber = 10

    const initData = () => {
      const todayDate = new Date().valueOf()
      const aDayStamp = 24 * 60 * 60 * 1000

      for (let i = 0; i < dayNumber; i++) {
        dateList[i] = formatDateTime('MM-dd-yyyy', todayDate - (aDayStamp * i))
      }
      dateList.reverse()
      curDate.value = dateList[dayNumber - 1]
      nextAble.value = false
      preAble.value = true
      ctx.emit('change', curDate.value)
    }
    initData()

    const change = (shift: number) => {
      const cur = dateList.indexOf(curDate.value)
      if (cur + shift > -1 && cur + shift < dayNumber) {
        curDate.value = dateList[cur + shift]
        preAble.value = true
        nextAble.value = true
        if (cur + shift === 0) {
          preAble.value = false
        }
        if (cur + shift === dayNumber - 1) {
          nextAble.value = false
        }
        ctx.emit('change', curDate.value)
      }
    }

    return {
      preAble,
      nextAble,
      curDate,
      change,
    }
  },
})

</script>

<style scoped lang="scss">
.date-sel{
  height: 32px;
  border-radius: 6px;
  border: 1px solid var(--color-border);
  .change-btn{
    height: 32px;
    display: flex;
    align-items: center;
    width: 32px;
    justify-content: center;
  }
  .pre{
    span{
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom:8px solid transparent;
      border-right:10px solid;
      border-right-color: var(--color-primary);
    }
  }
  .next{
    span{
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom:8px solid transparent;
      border-left:10px solid;
      border-left-color: var(--color-primary);
    }
  }
}
</style>
